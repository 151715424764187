import React from "react";


export const StillView = ({title, children}) => { 
    return ( 

        <div className="StandardPageContainer">
    <h1>test</h1>


        </div>
    )    
}